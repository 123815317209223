import React from 'react';
import styles from './Home.module.css';

function Home({ setIsLoggedIn, isUserApproved }) {
    return (
        <div className={styles.content}>
            <h1 className={styles.title}>Welcome to Scanwise</h1>
            <p className={styles.description}>Scanwise is a platform that allows you to automate your due-diligence process.</p>
            {/* Add more content for your home page */}
            {/* TODO: Add link to entities (name is organizations), link to payments, link to account */}
            {!isUserApproved && (
                <p className={styles.description}>
                    Thank you for creating your account! Your application is currently under review. 
                    Our team will process your access request shortly. If you have any questions, 
                    please don't hesitate to reach out to our support team.
                </p>
            )}
        </div>
    );
}

export default Home;
