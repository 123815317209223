import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';
import AuthService from '../services/AuthService';
import './AuthPage.module.css';
import styles from './AuthPage.module.css';

const AuthPage = ({ setIsLoggedIn, checkLoginStatus }) => {
    const [isLogin, setIsLogin] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [name, setName] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
    const [resetEmail, setResetEmail] = useState('');
    const [resetRequestSent, setResetRequestSent] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const popupTimeout = 10000;

    // Add this useEffect to handle query parameters
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const message = params.get('message');
        const error = params.get('error');
        const email = params.get('email');
        if (message) {
            let displayMessage = '';
            switch (message) {
                case 'verification_success':
                    displayMessage = 'Email verified successfully! Please login.';
                    break;
                case 'already_verified':
                    displayMessage = 'Email already verified. Please login.';
                    break;
                default:
                    displayMessage = message;
            }
            setIsError(false);
            setErrorMessage(displayMessage);
            setShowPopup(true);
            setIsLogin(true);
            setEmail(email || '');
        } else if (error) {
            let displayError = '';
            switch (error) {
                case 'invalid_token':
                    displayError = 'Invalid or expired verification link.';
                    break;
                case 'user_not_found':
                    displayError = 'User not found.';
                    break;
                default:
                    displayError = error;
            }
            setIsError(true);
            setErrorMessage(displayError);
            setShowPopup(true);
        }

        // Clear query parameters from URL
        if (message || error) {
            navigate(location.pathname, { replace: true });
            setTimeout(() => {
                setShowPopup(false);
                setErrorMessage('');
            }, popupTimeout);
        }
    }, [location, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Add password validation for signup
        if (!isLogin && password !== confirmPassword) {
            setIsError(true);
            setErrorMessage("Passwords don't match");
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
                setErrorMessage('');
            }, popupTimeout);
            return;
        }

        const endpoint = isLogin ? `${API_BASE_URL}/login` : `${API_BASE_URL}/signup`;
        const data = isLogin ? { email, password } : { name, email, password };

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                const result = await response.json();
                console.log(isLogin ? 'Logged in successfully' : 'Signed up successfully', result);

                if (isLogin) {
                    // Login flow remains the same
                    AuthService.setTokens(result.accessToken, result.refreshToken);
                    await checkLoginStatus();
                    setShowPopup(true);
                    setTimeout(() => {
                        setShowPopup(false);
                        navigate('/');
                    }, popupTimeout);
                } else {
                    // New signup success flow
                    setIsError(false);
                    setErrorMessage('Please check your email for a verification link.');
                    setShowPopup(true);
                    setIsLogin(true); // Switch to login form
                    // Keep the email field filled
                    setPassword(''); // Clear password for security
                    setTimeout(() => {
                        setShowPopup(false);
                        setErrorMessage('');
                    }, popupTimeout);
                }
            } else {
                const error = await response.json();
                console.error(isLogin ? 'Login failed' : 'Signup failed', error);
                if (error.error) {
                    setIsError(true);
                    setErrorMessage(error.error);
                    setShowPopup(true);
                    setTimeout(() => {
                        setShowPopup(false);
                        setErrorMessage('');
                    }, popupTimeout);
                }
            }
        } catch (error) {
            console.error('An error occurred:', error);
            setIsError(true);
            setErrorMessage('An error occurred');
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
                setErrorMessage('');
            }, popupTimeout);
        }
    };

    const handleResetPasswordClick = () => {
        setResetEmail(email); // Pre-fill with login email if exists
        setResetPasswordOpen(true);
    };

    const handleResetPasswordClose = () => {
        setResetPasswordOpen(false);
        setResetEmail('');
        setResetRequestSent(false);
    };

    const handleResetPasswordSubmit = async () => {
        if (!resetEmail) {
            setIsError(true);
            setErrorMessage('Please enter your email address');
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
                setErrorMessage('');
            }, popupTimeout);
            return;
        }

        try {
            const response = await fetch(`${API_BASE_URL}/reset-password-request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: resetEmail }),
            });

            if (response.ok) {
                setResetRequestSent(true);
                setIsError(false);
                setErrorMessage('Password reset instructions have been sent to your email.');
                setShowPopup(true);
                setTimeout(() => {
                    handleResetPasswordClose();
                    setShowPopup(false);
                    setErrorMessage('');
                }, popupTimeout);
            } else {
                const error = await response.json();
                setIsError(true);
                setErrorMessage(error.error || 'Failed to request password reset');
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                    setErrorMessage('');
                }, popupTimeout);
            }
        } catch (error) {
            setIsError(true);
            setErrorMessage('An error occurred while requesting password reset');
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
                setErrorMessage('');
            }, popupTimeout);
        }
    };

    return (
        <div className={styles['auth-page']}>
            {showPopup && (
                <div className={`${styles['popup']} ${isError ? styles['error-popup'] : styles['success-popup']}`}>
                    {errorMessage || 'Login successful!'}
                </div>
            )}
            <div className={styles['auth-container']}>
                <h2>{isLogin ? 'Login' : 'Sign Up'}</h2>
                <form onSubmit={handleSubmit}>
                    {!isLogin && (
                        <>
                            <input
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </>
                    )}
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    {!isLogin && (
                        <>
                            <input
                                type="password"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </>
                    )}
                    <button type="submit">{isLogin ? 'Login' : 'Sign Up'}</button>
                </form>
                <div className={styles['auth-links']}>
                    <button
                        type="button"
                        onClick={handleResetPasswordClick}
                        className={styles['forgot-password-link']}
                    >
                        Forgot your password?
                    </button>
                    <p className={styles['toggle-auth-mode']}>
                        {isLogin ? "Don't have an account? " : "Already have an account? "}
                        <button onClick={() => setIsLogin(!isLogin)}>
                            {isLogin ? 'Sign Up' : 'Login'}
                        </button>
                    </p>
                </div>
            </div>

            <Dialog
                open={resetPasswordOpen}
                onClose={handleResetPasswordClose}
                aria-labelledby="reset-password-dialog-title"
            >
                <DialogTitle id="reset-password-dialog-title">
                    {resetRequestSent ? 'Check Your Email' : 'Reset Password'}
                </DialogTitle>
                <DialogContent>
                    {resetRequestSent ? (
                        <DialogContentText>
                            If an account exists for {resetEmail}, you will receive password reset instructions at this email address.
                        </DialogContentText>
                    ) : (
                        <>
                            <DialogContentText>
                                Enter your email address and we'll send you instructions to reset your password.
                            </DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="reset-email"
                                label="Email Address"
                                type="email"
                                fullWidth
                                value={resetEmail}
                                onChange={(e) => setResetEmail(e.target.value)}
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleResetPasswordClose} color="primary">
                        {resetRequestSent ? 'Close' : 'Cancel'}
                    </Button>
                    {!resetRequestSent && (
                        <Button onClick={handleResetPasswordSubmit} color="primary">
                            Reset Password
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AuthPage;
