import { Alert, Box, Button, Paper, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API_BASE_URL } from '../config';
import styles from './ResetPassword.module.css';

interface ValidationErrors {
    newPassword?: string;
    confirmPassword?: string;
}

const ResetPassword: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState<ValidationErrors>({});
    const [submitError, setSubmitError] = useState<string>('');
    const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);
    const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null);

    useEffect(() => {
        const verifyToken = async () => {
            if (!token) {
                setIsTokenValid(false);
                return;
            }

            try {
                const response = await fetch(`${API_BASE_URL}/verify-reset-token`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token }),
                });

                setIsTokenValid(response.ok);
            } catch (error) {
                setIsTokenValid(false);
            }
        };

        verifyToken();
    }, [token]);

    const validateForm = (): boolean => {
        const newErrors: ValidationErrors = {};

        if (!newPassword) {
            newErrors.newPassword = 'New password is required';
        } else if (newPassword.length < 8) {
            newErrors.newPassword = 'Password must be at least 8 characters long';
        }

        if (!confirmPassword) {
            newErrors.confirmPassword = 'Please confirm your new password';
        } else if (newPassword !== confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setSubmitError('');
        setSubmitSuccess(false);

        if (!validateForm()) {
            return;
        }

        try {
            const response = await fetch(`${API_BASE_URL}/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token,
                    new_password: newPassword,
                }),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Failed to reset password');
            }

            setSubmitSuccess(true);
            // Reset form
            setNewPassword('');
            setConfirmPassword('');
            // Redirect to login after 2 seconds
            setTimeout(() => {
                navigate('/auth');
            }, 2000);
        } catch (error) {
            setSubmitError(error instanceof Error ? error.message : 'Failed to reset password');
        }
    };

    if (isTokenValid === null) {
        return (
            <Box className={styles.container}>
                <Typography>Verifying reset token...</Typography>
            </Box>
        );
    }

    if (isTokenValid === false) {
        return (
            <Box className={styles.container}>
                <Alert severity="error">
                    Invalid or expired password reset link. Please request a new password reset.
                </Alert>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/auth')}
                    sx={{ mt: 2 }}
                >
                    Back to Login
                </Button>
            </Box>
        );
    }

    return (
        <Box className={styles.container}>
            <Paper elevation={3} className={styles.formContainer}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Reset Password
                </Typography>

                {submitSuccess && (
                    <Alert severity="success" sx={{ mb: 2 }}>
                        Password reset successfully! Redirecting to login...
                    </Alert>
                )}

                {submitError && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {submitError}
                    </Alert>
                )}

                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        type="password"
                        label="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        error={!!errors.newPassword}
                        helperText={errors.newPassword}
                        margin="normal"
                    />

                    <TextField
                        fullWidth
                        type="password"
                        label="Confirm New Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword}
                        margin="normal"
                    />

                    <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            Reset Password
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            fullWidth
                            onClick={() => navigate('/auth')}
                        >
                            Cancel
                        </Button>
                    </Box>
                </form>
            </Paper>
        </Box>
    );
};

export default ResetPassword; 