import React, { useEffect, useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import './App.css';
import AuthService from './services/AuthService';

// Import new components
import Accounts from './components/Accounts';
import AdminRoot from './components/AdminRoot';
import AuthPage from './components/AuthPage';
import ChangePassword from './components/ChangePassword.tsx';
import DashboardMenu from './components/DashboardMenu';
import EntityDetails from './components/EntityDetails';
import Home from './components/Home';
import styles from './components/Home.module.css';
import ListOfEntities from './components/ListOfEntities';
import Payments from './components/Payments';
import ResetPassword from './components/ResetPassword.tsx';
import StaticFooter from './components/StaticFooter';
import StaticHeader from './components/StaticHeader';

// New component to wrap content
function ContentWrapper({ children, setIsLoggedIn, isAdmin, isUserApproved }) {
  return (
    <div className={styles.homeContainer}>
      <DashboardMenu className={styles.dashboardMenu} setIsLoggedIn={setIsLoggedIn} isAdmin={isAdmin} isUserApproved={isUserApproved} />
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isUserApproved, setIsUserApproved] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkLoginStatus = async () => {
    setIsLoading(true);
    try {
      const token = await AuthService.getValidToken();
      if (token) {
        setIsLoggedIn(true);
        // Fetch user details to check admin status
        const userDetails = await AuthService.getCurrentUser();
        setIsAdmin(userDetails.is_admin);
        setIsUserApproved(userDetails.user_approved);
      } else {
        setIsLoggedIn(false);
        setIsAdmin(false);
        setIsUserApproved(false);
      }
    } catch (error) {
      console.error('Error checking login status:', error);
      setIsLoggedIn(false);
      setIsAdmin(false);
      setIsUserApproved(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    checkLoginStatus();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  return (
    <Router>
      <div className="App">
        <StaticHeader isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />

        <Routes>
          <Route path="/auth" element={
            !isLoggedIn ? <AuthPage setIsLoggedIn={setIsLoggedIn} checkLoginStatus={checkLoginStatus} /> : <Navigate to="/" />
          } />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/*" element={
            <ContentWrapper setIsLoggedIn={setIsLoggedIn} isAdmin={isAdmin} isUserApproved={isUserApproved}>
              <Routes>
                <Route path="/" element={isLoggedIn ? <Home setIsLoggedIn={setIsLoggedIn} isUserApproved={isUserApproved} /> : <Navigate to="/auth" />} />
                <Route path="/entities" element={isLoggedIn ? <ListOfEntities setIsLoggedIn={setIsLoggedIn} /> : <Navigate to="/auth" />} />
                <Route path="/entity/:id" element={isLoggedIn ? <EntityDetails setIsLoggedIn={setIsLoggedIn} /> : <Navigate to="/auth" />} />
                <Route path="/entity_new" element={isLoggedIn ? <EntityDetails setIsLoggedIn={setIsLoggedIn} /> : <Navigate to="/auth" />} />
                <Route path="/payments" element={isLoggedIn ? <Payments setIsLoggedIn={setIsLoggedIn} /> : <Navigate to="/auth" />} />
                <Route path="/account" element={isLoggedIn ? <Accounts setIsLoggedIn={setIsLoggedIn} /> : <Navigate to="/auth" />} />
                <Route path="/change-password" element={isLoggedIn ? <ChangePassword /> : <Navigate to="/auth" />} />
                <Route path="/admin" element={isLoggedIn && isAdmin ? <AdminRoot setIsLoggedIn={setIsLoggedIn} /> : <Navigate to="/" />} />
                <Route path="*" element={<UnexpectedRoute />} />
              </Routes>
            </ContentWrapper>
          } />
        </Routes>

        <StaticFooter />
      </div>
    </Router>
  );
}

// New component to handle unexpected routes
function UnexpectedRoute() {
  const location = useLocation();
  console.log(`Unexpected route encountered: ${location.pathname}`);
  return <Navigate to="/" />;
}

export default App;
