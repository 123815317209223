import React, { useState, useEffect } from 'react';
import { ListItem, ListItemText, CircularProgress, LinearProgress, Typography, Button, Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import AuthService from '../services/AuthService';
import { API_BASE_URL } from '../config';

const EntityReport = ({ index, report, onReportDeleted, onDeleteConfirm }) => {
    const [downloadUrl, setDownloadUrl] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        const getDownloadUrl = async () => {
            const headers = await AuthService.getAuthHeader();
            const token = headers.Authorization.split(' ')[1]; // Assuming Bearer token
            setDownloadUrl(`${API_BASE_URL}/reports/${report.id}?token=${token}`);
        };

        if (report.generation_completed) {
            getDownloadUrl();
        }
    }, [report.id, report.generation_completed]);

    const formatCreationDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString(); // Convert to local timezone
    };

    const getTimeAgo = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

        if (diffInSeconds < 10) return 'just now';
        if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;
        if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
        if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
        return `${Math.floor(diffInSeconds / 86400)} days ago`;
    };

    const handleDelete = () => {
        onDeleteConfirm(report);
    };

    const handleDebugClick = () => {
        setDialogOpen(true);
    };

    return (
        <>
            <ListItem>
                <ListItemText
                    primary={
                        <>
                            Report created at: {formatCreationDate(report.created_at)}
                            <Typography variant="caption" sx={{ display: 'block', mt: 0.5 }}>
                                ({getTimeAgo(report.created_at)})
                            </Typography>
                        </>
                    }
                    secondary={
                        <Box>
                            {report.generation_failed && (
                                <>
                                    <ErrorOutlineIcon color="error" />
                                    {report.document_causing_error_description && (
                                        <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                            Error: {report.document_causing_error_description}
                                        </Typography>
                                    )}
                                    {report.document_causing_error_recovery_suggestion && (
                                        <Typography variant="body2" sx={{ mt: 1 }}>
                                            Suggestion: {report.document_causing_error_recovery_suggestion}
                                        </Typography>
                                    )}
                                </>
                            )}
                            {!report.generation_completed && !report.generation_failed && (
                                <>
                                    <Typography variant="caption" sx={{ display: 'block', mt: 0.5 }}>
                                        Report generation is in progress.
                                    </Typography>
                                    <CircularProgress size={20} />
                                    {report.generation_progress > 0 ? (
                                        <>
                                            <LinearProgress
                                                variant="determinate"
                                                value={report.generation_progress}
                                                sx={{ width: '100%', mt: 1 }}
                                            />
                                            <Typography variant="caption" sx={{ display: 'block', mt: 0.5 }}>
                                                Progress: {Math.round(report.generation_progress)}%
                                            </Typography>
                                        </>
                                    ) : (
                                        <LinearProgress
                                            variant="indeterminate"
                                            sx={{ width: '100%', mt: 1 }}
                                        />
                                    )}
                                    {report.generation_started_at && (
                                        <Typography variant="caption">
                                            Running for: {getTimeAgo(report.generation_started_at)}
                                        </Typography>
                                    )}
                                </>
                            )}
                            {report.generation_status_string && (
                                <Typography variant="body2">{report.generation_status_string}</Typography>
                            )}
                            {report.generation_completed && !report.generation_failed && (
                                <>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        startIcon={<DownloadIcon />}
                                        href={downloadUrl}
                                        download={`Scanwise_report_${report.id}.docx`}
                                        sx={{
                                            mt: 1,
                                            mr: 1,
                                            fontSize: '0.8rem',
                                            padding: '6px 16px',
                                        }}
                                    >
                                        DOWNLOAD REPORT
                                    </Button>
                                </>
                            )}
                            {report.debug_all_financial_documents_summary && (
                                <>
                                    <Typography 
                                        variant="body2" 
                                        onClick={handleDebugClick}
                                        sx={{ 
                                            cursor: 'pointer',
                                            padding: '8px',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                            }
                                        }}
                                    >
                                        <strong>Debug</strong> all financial documents summary: {
                                            report.debug_all_financial_documents_summary.slice(0, 200) + 
                                            (report.debug_all_financial_documents_summary.length > 200 ? '...' : '')
                                        }
                                    </Typography>
                                    <Dialog 
                                        open={dialogOpen} 
                                        onClose={() => setDialogOpen(false)}
                                        maxWidth="md"
                                        fullWidth
                                    >
                                        <DialogTitle>
                                            Debug Summary
                                            <IconButton
                                                aria-label="close"
                                                onClick={() => setDialogOpen(false)}
                                                sx={{
                                                    position: 'absolute',
                                                    right: 8,
                                                    top: 8,
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </DialogTitle>
                                        <DialogContent>
                                            <Typography 
                                                variant="body1" 
                                                sx={{ whiteSpace: 'pre-line' }}
                                            >
                                                {report.debug_all_financial_documents_summary}
                                            </Typography>
                                        </DialogContent>
                                    </Dialog>
                                </>
                            )}
                            <Button
                                variant="outlined"
                                color="error"
                                startIcon={<DeleteIcon />}
                                onClick={handleDelete}
                                sx={{
                                    mt: 1,
                                    fontSize: '0.8rem',
                                    padding: '4px 8px',
                                }}
                                size="small"
                            >
                                Delete
                            </Button>
                        </Box>
                    }
                />
            </ListItem>
        </>
    );
};

export default EntityReport;
