const hostname = window.location.hostname;
const protocol = window.location.protocol;
const port = window.location.port;
const origin = window.location.origin;

// In production/Docker, API is served from the same origin as the frontend
// In development, we need to specify a different port for the API.
// React in development usually served on port 3000, and Flask on port 5000.
export const API_BASE_URL = process.env.NODE_ENV === 'development'
  ? `http://localhost:${port === '3000' ? '5000' : port}/api/v1`
  : `${origin}/api/v1`;
