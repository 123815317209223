import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/AuthService';
import styles from './Home.module.css';

function Accounts({ setIsLoggedIn }) {
    const [user, setUser] = useState(null);
    const [account, setAccount] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [deleteConfirmText, setDeleteConfirmText] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = async () => {
        try {
            const userData = await AuthService.getCurrentUser();
            setUser(userData);
            if (userData && userData.user_approved) {
                fetchAccountData();
            }
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch user data');
            setLoading(false);
        }
    };

    const fetchAccountData = async () => {
        console.log('fetching account data');
        try {
            const accountData = await AuthService.getAccount();
            console.log(accountData);
            setAccount(accountData);
        } catch (err) {
            setError('Failed to fetch account data');
        }
    };

    const handleDeleteClick = () => {
        setDeleteConfirmOpen(true);
    };

    const handleDeleteCancel = () => {
        setDeleteConfirmOpen(false);
    };

    const handleDeleteConfirmed = async () => {
        if (deleteConfirmText.toLowerCase() !== 'delete') {
            return;
        }
        try {
            await AuthService.deleteAccount();
            setIsLoggedIn(false);
            navigate('/');
        } catch (err) {
            console.error(err);
            setError('Failed to delete account');
        }
        setDeleteConfirmOpen(false);
        setDeleteConfirmText('');
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div>
            <h1 className={styles.title}>Account Information</h1>
            {user && (
                <>
                    <div className={styles.userInfo}>
                        <p><strong>Name:</strong> {user.name}</p>
                        <p><strong>Email:</strong> {user.email}</p>
                        <p><strong>User approved:</strong> {user.user_approved ? 'Yes' : 'No'}</p>
                        {user.is_admin && <p><strong>Admin:</strong> Yes</p>}
                        {account?.balance != null && (
                            <p>
                                <strong>Account balance:</strong> {Number(account.balance).toLocaleString('en-US', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                })}
                            </p>
                        )}
                    </div>
                    <div className={styles.buttonContainer} style={{ display: 'flex', flexDirection: 'column', gap: '1rem', maxWidth: '300px', margin: '2rem auto' }}>
                        <Button 
                            variant="contained"
                            color="primary"
                            onClick={() => navigate('/change-password')}
                            fullWidth
                        >
                            Change Password
                        </Button>
                        <Button 
                            variant="contained"
                            color="error"
                            onClick={handleDeleteClick}
                            fullWidth
                        >
                            Delete Account
                        </Button>
                    </div>

                    <Dialog
                        open={deleteConfirmOpen}
                        onClose={handleDeleteCancel}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to delete your account? This action cannot be undone.
                                Please type "delete" to confirm.
                            </DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                fullWidth
                                value={deleteConfirmText}
                                onChange={(e) => setDeleteConfirmText(e.target.value)}
                                placeholder="Type 'delete' to confirm"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDeleteCancel} color="primary">
                                Cancel
                            </Button>
                            <Button 
                                onClick={handleDeleteConfirmed} 
                                color="primary"
                                disabled={deleteConfirmText.toLowerCase() !== 'delete'}
                            >
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </div>
    );
}

export default Accounts;
